import React from 'react';
import { trans } from '../../../../_providers/Translation';

function FilterReservations(props) {

    const handleSearch = e => {
        let name = e.target.name;
        let val = e.target.value;

        props.handleSearch(name, val);
    }

    return (
        <div className="filter-standard">
            <div className="col">
                <label>
                    {trans('filters.status')}
                </label>
                <select
                    name="status"
                    value={props.filter.status}
                    onChange={handleSearch}
                >
                    <option value="" selected>
                        {trans('random.select')}
                    </option>
                    <option value="pending">
                        {trans('random.pendding')}
                    </option>
                    <option value="closed">
                        {trans('random.closed')}
                    </option>
                </select>
            </div>

            <div className="col">
                <label>
                    {trans('labels.from')}
                </label>
                <input
                    type="date"
                    name="date"
                    value={props.filter.date}
                    onChange={handleSearch}
                />
            </div>
            <div className="col">
                <label>
                    {trans('labels.to')}
                </label>
                <input
                    type="date"
                    name="deadline"
                    value={props.filter.deadline}
                    onChange={handleSearch}
                />
            </div>

            <div className="col">
                <label>
                    {trans('labels.search')}
                </label>
                <input
                    type="search"
                    name="search"
                    value={props.filter.search}
                    onChange={handleSearch}
                />
            </div>

            <div className="col">
                <label>
                    {trans('selfEmployed.paidStatus.title')}
                </label>
                <select
                    name="paid_status"
                    value={props.filter.paid_status}
                    onChange={handleSearch}
                >
                    <option value="" selected>
                        {trans('random.select')}
                    </option>
                    <option value="paid">
                        {trans('selfEmployed.paidStatus.paid')}
                    </option>
                    <option value="not_paid">
                        {trans('selfEmployed.paidStatus.notPaid')}
                    </option>
                </select>
            </div>
        </div>
    )
}

export default React.memo(FilterReservations, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
});
import React, { useState, useImperativeHandle, useEffect, forwardRef, useRef } from 'react';
import Api from '../../../../_helpers/Api';
import { trans } from '../../../../_providers/Translation';

//images
import docIcon from '../../../../assets/img/icons/document.png';
import closeIcon from '../../../../assets/img/icons/close.png';
import deleteIcon from '../../../../assets/img/icons/delete-red.png';
import loader from '../../../../assets/img/loader.svg';
import Dropdown from '../../../partials/Dropdown';

function AddOrEdit(props, ref) {

    const inputFileRef = useRef(null);

    const defaultData = {
        vat_expense: 1,
        vat: 20,
        files: []
    }

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        edit: false,
        editId: null,
        data: defaultData,
        loading: false,
    });

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        add: () => {
            setPopup(prev => ({
                ...prev,
                edit: false,
                loading: false,
                data: defaultData
            }));
            handleShow();
        },
        edit: (id) => {
            setPopup(prev => ({
                ...prev,
                edit: Math.random().toString(),
                editId: id,
                loading: false
            }));
            handleShow();
        },
        hide: () => {
            handleHide();
        }
    }));

    // при редакция извлича текущите данни, за да се попълнят полетата
    useEffect(() => {
        if (popup.edit) {
            Api.get(`expenses/find?id=${popup.editId}`)
                .then(res => {
                    setPopup(prev => ({
                        ...prev,
                        data: res.data,
                        tab: 1
                    }));
                });
        }
    }, [popup.edit]);

    useEffect(() => {
        if (Number(popup.data.vat_expense) === 0) {
            setPopup(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    vat: 0
                }
            }));
        }
    }, [popup.data.vat_expense]);

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();


        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url;

        if (popup.edit) {
            url = 'expenses/edit';
        } else {
            url = 'expenses/add';
        }

        let data = new FormData(e.target);

        // при редакция - добавяме ID
        if (popup.edit) {
            data.append('id', popup.editId);
        }

        popup.data.files.map(f => {
            if (f.was_recently_attached) {
                data.append('files[]', f);
            }
        })

        Api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(res => {

            if (popup.edit) {
                if (props.handleUpdate) {
                    props.handleUpdate(res.data);
                }
            } else {
                if (props.handleCreate) {
                    props.handleCreate(res.data);
                }
            }

            handleHide(e);

        }).catch(error => {

            setPopup(prev => ({
                ...prev,
                loading: false,
            }));

            let _err = error.response;

            if (_err && _err.status && _err.status == 422) {
                for (let [input, err] of Object.entries(_err.data.errors)) {

                    setValidations(prevState => {
                        return { ...prevState, [input]: true }
                    });
                }
            }

        });

    }

    const handleChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }))
    }

    const handleCheckboxChange = e => {
        let name = e.target.name;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: !prev.data[name]
            }
        }))
    }

    const handleDateChange = e => {
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                date_ymd: val
            }
        }))
    }

    const handleShowAttach = e => {
        e.preventDefault();

        inputFileRef.current.click();
    }

    const handleAttach = e => {
        let files = [...e.target.files];

        files.forEach((f, i) => {
            let url = URL.createObjectURL(f);

            files[i].id = Math.random().toString(7).substring(2);
            files[i].was_recently_attached = true;
            files[i].url = url;
        });

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                files: prev.data.files.concat(files)
            }
        }));
    }

    const handleDetach = id => {

        let file = popup.data.files.filter((f, i) => {
            return Number(f.id) === Number(id);
        })[0];

        if (!file.was_recently_attached) {
            Api.post('expenses/remove-file', {
                id: id
            }).then(res => {
                if (res.data.success) {
                    removeFile(id);
                }
            });
        } else {
            removeFile(id);
        }
    }

    const removeFile = id => {
        let files = popup.data.files.filter((f, i) => {
            return Number(f.id) !== Number(id);
        });

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                files: files
            }
        }));
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form id="form" className={`${popup.modal ? 'show' : ''} popup-primary large`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />

                    {popup.edit ? trans('headings.editExpense') : trans('headings.addExpense')}

                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                <div className="popup-container">

                    <div className="col">
                        <label htmlFor="">
                            {trans('labels.category')}
                        </label>
                        <Dropdown
                            key={'category-' + popup.data?.id}
                            inputIdName="category_id"
                            inputValue={popup.data?.category?.name}
                            inputIdValue={popup.data && popup.data.category_id ? popup.data.category_id : ''}
                            inputClassName={validations && validations.category_id ? 'validate' : ''}
                            url="expenses/categories/all"
                            params={{
                                limit: 100
                            }}
                        />
                    </div>

                    <div className="col">
                        <label htmlFor="">
                            {trans('labels.date')}
                        </label>
                        <input
                            type="date"
                            name="date"
                            value={popup.data && popup.data.date_ymd ? popup.data.date_ymd : ''}
                            className={validations && validations.date ? 'validate' : ''}
                            onChange={handleDateChange}
                        />
                    </div>

                </div>

                <br />

                <div className="popup-container">

                    <div className="col">
                        <label>
                            {trans('labels.ddsExpense')}
                        </label>
                        <select
                            name="vat_expense"
                            value={popup.data.vat_expense}
                            onChange={handleChange}
                        >
                            <option value="1">{trans('labels.yes')}</option>
                            <option value="0">{trans('labels.no')}</option>
                        </select>

                    </div>

                    <div className="col">
                        <label htmlFor="">
                            {trans('labels.dds')} (%)
                        </label>
                        <input
                            type="number"
                            min="0"
                            step="0.01"
                            name="vat"
                            value={popup.data && typeof popup.data.vat !== 'undefined' ? popup.data.vat : ''}
                            onChange={handleChange}
                            className={validations && validations.vat ? 'validate' : ''}
                            disabled={popup.data && Number(popup.data.vat_expense) === 0}
                        />
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label htmlFor="">
                            {trans('tables.netSum')}
                        </label>
                        <input
                            type="number"
                            min="0"
                            step="0.01"
                            name="price"
                            value={popup.data && popup.data.price ? popup.data.price : ''}
                            onChange={handleChange}
                            className={validations && validations.price ? 'validate' : ''}
                        />
                    </div>

                    <div className="col" style={{ paddingTop: '25px' }}>
                        <label className="flex">
                            <input
                                type="checkbox"
                                name="receipt"
                                value="0"
                                checked={!popup.data.receipt ? true : false}
                                onChange={handleCheckboxChange}
                            />
                            <span>
                                {trans('labels.noDocNeeded')}
                            </span>
                        </label>
                    </div>
                </div>

                <br />

                <div className="row">
                    <label>
                        {trans('labels.files')}
                    </label>
                </div>

                {popup.data.files.length === 0
                    ?
                    <div className="row">
                        <span style={{ fontSize: '12px' }}>
                            {trans('messages.noFile')}
                        </span>
                    </div>
                    :
                    ''
                }

                {popup.data.files.map(f =>
                    <div key={'f-' + f.id} className="popup-container" style={{
                        marginBottom: '10px',
                        alignItems: 'center'
                    }}>
                        <div className="col">
                            <label>
                                <a href={f.url} target="_blank">{f.name}</a>
                            </label>
                        </div>
                        <div className="col auto" style={{ width: 'fit-content' }}>
                            <img onClick={() => handleDetach(f.id)} src={deleteIcon} style={{ cursor: 'pointer', width: 'fit-content' }} />
                        </div>
                    </div>
                )}

                <button
                    className="button-small"
                    onClick={handleShowAttach}
                >
                    {trans('buttons.upload')}
                </button>

                <input ref={inputFileRef} type="file" onChange={handleAttach} hidden multiple />

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.description')}
                        </label>
                        <textarea
                            name="description"
                            value={popup.data.description ? popup.data.description : ''}
                            onChange={handleChange}
                            className={validations && validations.description ? 'validate' : ''}
                        ></textarea>
                    </div>
                </div>


                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(AddOrEdit);
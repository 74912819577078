import React, { useRef } from 'react';
import NoDataFound from '../../../partials/NoDataFound';
import { trans } from '../../../../_providers/Translation';
import EmployeesOptions from '../../partner/partials/EmployeesOptions';
import { useMessagesContext } from '../../../../_providers/Messages';
import EmployeeFreeDays from '../../partner/partials/EmployeeFreeDays';
import { Link } from 'react-router-dom';

function Table(props) {

    const messages = useMessagesContext();

    const freeDaysModalRef = useRef(null);

    const { handleEdit, handleDelete } = props;

    const handleOpenChat = id => {

        let user = props.data.filter(i => {
            return i.id === id;
        })[0];

        messages.openChat(user.id, user.type);
    }

    const handleShowFreeDays = id => {
        freeDaysModalRef.current.show(id);
    }

    return (
        <>

            <EmployeeFreeDays
                ref={freeDaysModalRef}
            />

            {props.data.length === 0
                ?
                <NoDataFound />
                :
                <>
                    <div className="table-scroller">
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: '50px' }}>ID</th>
                                    <th>{trans('labels.picture')}</th>
                                    <th>{trans('labels.name')}</th>
                                    <th>{trans('labels.email')}</th>
                                    <th>{trans('labels.phone')}</th>
                                    <th>{trans('labels.address')}</th>
                                    <th>{trans('labels.active')}</th>
                                    <th>{trans('labels.startDate')}</th>
                                    <th>{trans('labels.leaveDate')}</th>
                                    <th className="options">{trans('tables.options')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.data.map(e =>
                                    <tr key={'e' + e.id}>
                                        <td>{e.id}</td>
                                        <td><img src={e.avatar} alt='' width={40} height={40} style={{ objectFit: 'cover' }} /></td>
                                        <td>
                                            <Link to={'/relationships/selfemployed/' + e.id}>
                                                {e.name} {e.lastname}
                                            </Link>
                                        </td>
                                        <td>{e.email}</td>
                                        <td>{e.phone_formatted}</td>
                                        <td>{e.address}</td>
                                        <td>{e.active ? trans('labels.yes') : trans('labels.no')}</td>
                                        <td>{e.start_date}</td>
                                        <td>{e.leave_date}</td>
                                        <td className="options">
                                            <EmployeesOptions
                                                id={e.id}
                                                handleEdit={handleEdit}
                                                handleOpenChat={handleOpenChat}
                                                handleDelete={handleDelete}
                                                handleShowFreeDays={handleShowFreeDays}
                                            />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </>
            }
        </>
    )
}

export default Table;
import React, { useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { trans } from '../../../../_providers/Translation';

function Index(props) {

    const handleTab = (e, tab) => {
        props.handleTab(tab);
    }

    return (
        <Tabs
            value={props.tab}
            onChange={handleTab}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs"
        >
            <Tab value="sent" label={trans('tabs.partnersToReserve')} disableRipple={true} />
            <Tab value="received" label={trans('tabs.partnersForReserve')} disableRipple={true} />
            <Tab value="external" label={trans('tabs.selfEmployed')} disableRipple={true} />

        </Tabs>
    )
}

export default Index;
import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useAuthDataContext } from '../../../../_providers/Auth';
import { trans } from '../../../../_providers/Translation';

function FilterTabs(props) {

    const auth = useAuthDataContext();

    const [tabs, setTabs] = useState({
        ripple: true
    })
    const handleTabs = (e, newValue) => {
        props.handleTab(newValue);
    };

    return (
        <Tabs
            value={props.tab}
            onChange={handleTabs}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
        >
            <Tab value="incomes" label={trans('headings.incomes')} disableRipple={tabs.ripple} />
            <Tab value="expenses" label={trans('headings.expenses')} disableRipple={tabs.ripple} />
            <Tab value="vat" label={trans('random.ddsTax')} disableRipple={tabs.ripple} />
            <Tab value="profit-vat" label={trans('random.profitTax')} disableRipple={tabs.ripple} />

            {/* {!auth.isAdmin() &&
                <Tab value="unpaid-reservations" label="Неплатени резервации" disableRipple={tabs.ripple} />
            }
            {!auth.isAdmin() &&
                <Tab value="unpaid" label="Компании с неплатени фактури" disableRipple={tabs.ripple} />
            } */}
        </Tabs>
    )
}

export default FilterTabs;
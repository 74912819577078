import React, { useEffect, useRef, useState } from 'react'
import { Switch, Route, useLocation, Redirect, useHistory } from "react-router-dom"
import Header from './components/app/Header'
import Aside from './components/app/Aside'

//pages
import Todo from './components/pages/todo/Index'
import Dashboard from './components/pages/dashboard/Index'
import Branches from './components/pages/branches/Index'
// import Brands from './components/pages/brands/Index'
// import Categories from './components/pages/categories/Index'
// import Appliances from './components/pages/appliances/Index'
import Manuals from './components/pages/manuals/Index'
import Partners from './components/pages/partners/Index'
import Partner from './components/pages/partner/Index'
import Suppliers from './components/pages/suppliers/Index'
import Licenses from './components/pages/licenses/Index'
import Parts from './components/pages/parts/Index'
import PartsStats from './components/pages/parts/Stats'
import Storage from './components/pages/storage/Index'
import MostUsedParts from './components/pages/storage/MostUsed'
import Login from './components/pages/login/Login'
import Contractors from './components/pages/contractors/Index'
import Contractor from './components/pages/contractor/Index'
import Reservation from './components/pages/reservation/Index'
import ReservationCreateEdit from './components/pages/reservation/CreateEdit'
import Schedule from './components/pages/schedule/Index'
import Invoices from './components/pages/invoices/Index'
import CustomInvoice from './components/pages/invoices/CustomInvoice'
import UnpaidReservations from './components/pages/invoices/UnpaidReservations'
import Accounts from './components/pages/accounts/Index'
import Expenses from './components/pages/expenses/Expenses'
import ExpensesCategories from './components/pages/expenses/Categories'
import Settings from './components/pages/profile/Settings'
// import Customers from './components/pages/customers/Index'
import MainSettings from './components/pages/mainsettings/Index'
import WebSites from './components/pages/websites/Index'
import Relationships from './components/pages/relationships/Index'
import Relationship from './components/pages/relationship/Index'
import SelfEmployed from './components/pages/selfemployed/Index'
import SelfEmployedIndex from './components/pages/selfemployed_index/Index'
import Admins from './components/pages/admins/Index'
import BankTransactions from './components/pages/banktransactions/Index'
import Sms from './components/pages/sms/Index'
import SmsPackage from './components/pages/sms-package/Index'
import VoiceCalls from './components/pages/voicecalls/Index'
import BonusPolicies from './components/pages/bonuspolicies/Index'

//style
import './assets/css/app.css'
import './assets/css/page.css'
import './assets/css/message-modal.css'
import './assets/css/buttons.css'
import './assets/css/fields.css'
import './assets/css/tables.css'
import './assets/css/filter.css'
import './assets/css/page-tab.css'
import './assets/css/options.css'
import './assets/css/modal-primary.css'
import './assets/css/groups.css'
import './assets/css/message-panel.css'
import './assets/css/instant-notification.css'

import { useAuthDataContext } from './_providers/Auth'

import SocketProvider from './_providers/Socket'
import UpcomingReservationsModal from './components/modals/UpcomingReservations'
import ActivityModal from './components/modals/Activity'
import ActivationError from './components/modals/messages/ActivationError'
import MaxActiveEmployeesExceededError from './components/modals/messages/MaxActiveEmployeesExceededError'
import NotificationsProvider from './_providers/Notifications'
import MessagesProvider from './_providers/Messages'
import MessagesContainer from './components/partials/MessagesContainer'
import NotificationsContainer from './components/partials/NotificationsContainer'
import { plural, trans } from './_providers/Translation'
import ProtectedRoute from './_helpers/ProtectedRoute'

import moment from 'moment'
import VoiceCallProvider from './_providers/VoiceCall'
import PageLoader from './components/partials/PageLoader'

let timerInterval;

function App() {

	const auth = useAuthDataContext()

	const locale = auth.lang()

	const history = useHistory()
	const location = useLocation()

	const [booted, setBooted] = useState(false);

	const [bootedServices, setBootedServices] = useState({
		user: false,
		// langs: false,
		// currency: false,
	});

	const searchParams = new URLSearchParams(location.search);
	const showUpcomingReservations = searchParams.get('show_upcoming_reservations');
	const showUpcomingReservationsForDate = searchParams.get('for_date');
	const showActivity = searchParams.get('show_activity');
	const showActivityForSubjectId = searchParams.get('subject_id');
	const showActivityForSubjectType = searchParams.get('subject_type');

	const upcomingReservationsModalRef = useRef(null);
	const activityModalRef = useRef(null);
	const activationErrorModalRef = useRef(null);
	const maxActiveEmployeesErrorModalRef = useRef(null);

	useEffect(() => {
		if (auth.isLogged) {
			auth.loadData()
				.then((result) => {
					if (result === true) {
						makeBooted('user');
					}
				});
		} else {
			makeBooted('user');
		}
	}, []);


	useEffect(() => {
		let all = Object.values(bootedServices).length;
		let booted = Object.values(bootedServices).filter(booted => booted === true).length;

		console.log(booted);

		setBooted(all === booted);

	}, [bootedServices]);

	// активация
	useEffect(() => {
		if (!booted) {
			return;
		}

		let modal = activationErrorModalRef.current;

		if (!modal) {
			return;
		}

		if (auth.partner()) {
			if (Number(auth.partner().active) === 0) {
				modal.open();
			} else {
				modal.close();
			}
		}
	}, [booted, auth, location]);

	// служители
	useEffect(() => {
		if (!booted) {
			return;
		}

		let modal = maxActiveEmployeesErrorModalRef.current;

		if (!modal) {
			return;
		}

		modal.onClose(() => {
			history.push('/company?tab=employees');
		});

		if (auth.partner()) {
			if (auth.partner().max_employees > 0 && auth.partner().active_employees_count > auth.partner().max_employees) {
				modal.open();
			} else {
				// modal.close();
			}
		}
	}, [booted, auth, location]);

	useEffect(() => {
		handleRouteChange()
	}, [location])

	useEffect(() => {
		if (showUpcomingReservations) {
			handleShowUpcoming();
		}
	}, [showUpcomingReservations])

	useEffect(() => {
		if (showActivity || (showActivityForSubjectId && showActivityForSubjectType)) {
			handleShowActivity();
		}
	}, [showActivity, showActivityForSubjectId, showActivityForSubjectType])

	useEffect(() => {

		timerInterval = setInterval(() => {
			let els = document.querySelectorAll('.timer');

			let now = new Date(new Date().toLocaleString("en-US", { timeZone: "UTC" }));

			els.forEach(el => {
				let date = el.getAttribute('data-date');

				if (date) {
					date = new Date(date);

					let text = convertDateToText(now, date);

					if (text) {
						el.innerHTML = text;
					}
				}
			})

		}, 10000);


		return () => {
			clearInterval(timerInterval);
		}
	}, []);

	useEffect(() => {

		// if (locale != 'en') {
		moment.locale(locale, {
			months: trans('dates.months'),
			monthsShort: trans('dates.monthsShort'),
			monthsParseExact: true,
			weekdays: trans('dates.weekdays'),
			weekdaysShort: trans('dates.weekdaysShort'),
			weekdaysMin: trans('dates.weekdaysMin'),
			weekdaysParseExact: true,
			longDateFormat: {
				LT: 'HH:mm',
				LTS: 'HH:mm:ss',
				L: 'DD/MM/YYYY',
				LL: 'D MMMM YYYY',
				LLL: 'D MMMM YYYY HH:mm',
				LLLL: 'dddd D MMMM YYYY HH:mm'
			},
			calendar: {
				sameDay: '[Aujourd’hui à] LT',
				nextDay: '[Demain à] LT',
				nextWeek: 'dddd [à] LT',
				lastDay: '[Hier à] LT',
				lastWeek: 'dddd [dernier à] LT',
				sameElse: 'L'
			},
			relativeTime: {
				future: 'dans %s',
				past: 'il y a %s',
				s: 'quelques secondes',
				m: 'une minute',
				mm: '%d minutes',
				h: 'une heure',
				hh: '%d heures',
				d: 'un jour',
				dd: '%d jours',
				M: 'un mois',
				MM: '%d mois',
				y: 'un an',
				yy: '%d ans'
			},
			dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
			ordinal: function (number) {
				return number + (number === 1 ? 'er' : 'e');
			},
			meridiemParse: /PD|MD/,
			isPM: function (input) {
				return input.charAt(0) === 'M';
			},
			// In case the meridiem units are not separated around 12, then implement
			// this function (look at locale/id.js for an example).
			// meridiemHour : function (hour, meridiem) {
			//     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
			// },
			meridiem: function (hours, minutes, isLower) {
				return hours < 12 ? 'PD' : 'MD';
			},
			week: {
				dow: 1, // Monday is the first day of the week.
				doy: 4  // Used to determine first week of the year.
			}
		});
		// }

	}, [locale])

	useEffect(() => {
		const handleVisibleOverlay = e => {
			let el = document?.querySelector('.overlay.visible');

			if (el) {
				document.body.style.overflow = 'hidden';
			} else {
				document.body.style.overflow = 'auto';
			}
		}

		const onClick = e => {
			setTimeout(() => {
				handleVisibleOverlay(e);
			}, 300);
		}

		document.addEventListener('click', onClick);
		document.addEventListener('mousemove', handleVisibleOverlay);

		return () => {
			document.removeEventListener('click', onClick);
			document.removeEventListener('mousemove', handleVisibleOverlay);
		}
	}, []);

	const makeBooted = (service, booted = true) => {
		console.log('booted ' + service);

		setBootedServices(prev => ({
			...prev,
			[service]: booted
		}));
	}

	const convertDateToText = (date1, date2) => {

		let diff = (date1 - date2) / 1000;

		let hours = Math.floor(diff / 3600);
		let minutes = Math.floor((diff / 60) % 60);
		let seconds = diff % 60;

		if (hours > 24) {
			return;
		}

		if (hours > 0) {
			return plural('dates.published.hours', hours, {
				count: hours
			});
		}

		if (minutes > 0) {
			return plural('dates.published.minutes', minutes, {
				count: minutes
			});
		}

		if (seconds > 0) {
			return plural('dates.published.seconds', seconds, {
				count: seconds
			});
		}

		return trans('dates.published.now');
	}

	const handleRouteChange = () => {
		let menu = document.querySelector('aside')

		if (menu) {
			menu.classList.remove('visable-aside')
		}
	}

	const handleShowUpcoming = () => {
		upcomingReservationsModalRef.current.open(showUpcomingReservations, showUpcomingReservationsForDate);
	}

	const handleHideUpcoming = () => {
		searchParams.delete('show_upcoming_reservations');
		searchParams.delete('for_date');

		let url = '?' + searchParams.toString();

		history.replace(url);
	}

	const handleShowActivity = () => {
		activityModalRef.current.open(showActivity);
	}

	const handleHideActivity = () => {
		searchParams.delete('show_activity');

		let url = '?' + searchParams.toString();

		history.replace(url);
	}

	const segment = index => {
		let path = location.pathname;
		let array = path.replace('/', '').split('/');
		let segment = array[index] || '';

		return segment;
	}

	if (!booted) {
		return <PageLoader show />
	}

	return (
		<div className="App">

			<ActivationError
				ref={activationErrorModalRef}
			/>

			{auth.partner() &&
				<MaxActiveEmployeesExceededError
					ref={maxActiveEmployeesErrorModalRef}
					count={auth.partner() ? auth.partner().active_employees_count - auth.partner().max_employees : 0}
				/>
			}

			<Route path="/login/:role" exact component={Login} />

			{!auth.isLogged
				?
				<main>
					{segment(0) !== 'login' &&
						<Route path="*" exact render={() => <Redirect to={'/login/partners?' + searchParams.toString()} />} />
					}
				</main>
				:
				segment(0) !== 'login'
					?
					<SocketProvider>
						<VoiceCallProvider>
							<NotificationsProvider>
								<MessagesProvider>
									<Header />
									<main>
										<Aside />
										<section className="content">
											<Route render={({ location }) => (
												<Switch location={location}>

													<ProtectedRoute path="/dashboard" access={auth.isManager() || auth.access('dashboard')} exact component={Dashboard} />

													<ProtectedRoute path="/partners" access={auth.access('services')} exact component={Partners} />
													<ProtectedRoute path="/partners/:id" access={auth.access('partners')} exact component={Partner} />
													<ProtectedRoute path="/admins" access={auth.isSuperAdmin()} exact component={Admins} />
													<ProtectedRoute path="/sms-package" access={auth.isSuperAdmin()} exact component={SmsPackage} />
													<ProtectedRoute path="/branches" access={auth.access('branches')} exact component={Branches} />
													{/* <ProtectedRoute path="/categories" access={auth.access('appliances')} exact component={Categories} /> */}
													{/* <ProtectedRoute path="/brands" access={auth.access('appliances')} exact component={Brands} /> */}
													{/* <ProtectedRoute path="/appliances" access={auth.isManager() || auth.isOperator()} exact component={Appliances} /> */}
													<ProtectedRoute path="/licenses" access={auth.access('licenses')} exact component={Licenses} />
													<ProtectedRoute path="/settings" access={auth.isSuperAdmin()} exact component={MainSettings} />

													{/* <Route path="/partners" exact component={Partners} /> */}
													<ProtectedRoute path="/todo" access={auth.access('todos')} exact component={Todo} />
													<ProtectedRoute path="/manuals" access={auth.access('manuals')} exact component={Manuals} />
													<ProtectedRoute path="/website" access={auth.access('website')} exact component={WebSites} />
													<ProtectedRoute path="/company" access={auth.isManager()} exact component={Partner} />
													<ProtectedRoute path="/bonuspolicies" access={auth.access('bonuspolicy')} exact component={BonusPolicies} />
													{/* <Route path="/customers" exact component={Customers} /> */}
													<ProtectedRoute path="/parts/list" access={auth.access('parts')} exact component={Parts} />
													<ProtectedRoute path="/parts/storage/:id?" access={auth.access('parts')} exact component={Storage} />
													<ProtectedRoute path="/parts/most-used" access={auth.access('parts')} exact component={MostUsedParts} />
													<ProtectedRoute path="/parts/suppliers" access={auth.access('parts')} exact component={Suppliers} />
													<ProtectedRoute path="/parts/stats" access={auth.access('parts')} exact component={PartsStats} />
													<ProtectedRoute path="/contractors" access={auth.access('contractors')} exact component={Contractors} />
													<ProtectedRoute path="/contractors/:id" access={auth.access('contractors')} exact component={Contractor} />
													<ProtectedRoute path="/relationships" access={auth.access('partners')} exact component={Relationships} />
													<ProtectedRoute path="/relationships/selfemployed" access={auth.access('partners')} exact component={SelfEmployed} />
													<ProtectedRoute path="/relationships/selfemployed/:id" access={auth.access('partners')} exact component={SelfEmployedIndex} />
													<ProtectedRoute path="/relationships/:id" access={auth.access('partners')} exact component={Relationship} />
													<ProtectedRoute path="/reservations" access={auth.access('reservations')} exact component={Reservation} />
													<ProtectedRoute path="/reservations/:type/add" access={auth.access('reservations')} exact component={ReservationCreateEdit} />
													<ProtectedRoute path="/reservations/edit/:id" access={auth.access('reservations')} exact component={ReservationCreateEdit} />
													<ProtectedRoute path="/schedule" access={auth.access('schedule')} exact component={Schedule} />
													<ProtectedRoute path="/invoices" access={auth.access('invoices')} exact component={Invoices} />
													<ProtectedRoute path="/invoices/add" access={auth.access('invoices')} exact component={CustomInvoice} />
													<ProtectedRoute path="/invoices/edit/:id" access={auth.access('invoices')} exact component={CustomInvoice} />
													<ProtectedRoute path="/invoices/unpaid-reservations" exact component={UnpaidReservations} />
													<ProtectedRoute path="/accounts/:type" access={auth.access('accounts')} exact component={Accounts} />
													<ProtectedRoute path="/expenses" access={auth.access('expenses')} exact component={Expenses} />
													<ProtectedRoute path="/expenses/categories" access={auth.access('expenses')} exact component={ExpensesCategories} />
													<ProtectedRoute path="/banktransactions" access={auth.access('banktransactions')} exact component={BankTransactions} />
													<ProtectedRoute path="/sms/:type" access={auth.access('sms')} exact component={Sms} />
													<ProtectedRoute path="/voicecalls" access={auth.access('voicecalls')} exact component={VoiceCalls} />
													<ProtectedRoute path="/profile/settings" exact component={Settings} />
												</Switch>
											)} />
										</section>
									</main>

									<MessagesContainer />
									<NotificationsContainer />

									<UpcomingReservationsModal
										ref={upcomingReservationsModalRef}
										// id={showUpcomingReservations}
										// date={showUpcomingReservationsForDate}
										onClose={handleHideUpcoming}
									/>

									<ActivityModal
										ref={activityModalRef}
										onClose={handleHideActivity}
									/>

								</MessagesProvider>
							</NotificationsProvider>
						</VoiceCallProvider>
					</SocketProvider>
					:
					''
			}

		</div>

	);
}

export default App;


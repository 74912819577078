import React, { useEffect, useState, useRef, useCallback } from 'react';
import Table from './partials/Table';
import Api from '../../../_helpers/Api';
import Pagination from '../../Pagination';
import { useHistory, useLocation } from 'react-router-dom';
import { fromInfo, trans } from '../../../_providers/Translation';

//modals
import AddOrEdit from './partials/AddOrEdit';
import Question from '../../modals/messages/Question';
import Filter from './partials/Filter';
import Skeleton from 'react-loading-skeleton';
import AddExisting from './partials/AddExisting';
import Tabs from './partials/Tabs';
import Settings from './partials/Settings';
import update from 'immutability-helper';
import Help from '../../partials/Help';

function Index() {

    const history = useHistory();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);

    const modalAddOrEditRef = useRef(null);
    const modalAddExistRef = useRef(null);
    const modalQuestionRef = useRef(null);
    const modalSettingsRef = useRef(null);

    const groups = ['sent', 'received', 'external'];

    const group = searchParams.get('group') && groups.includes(searchParams.get('group')) ? searchParams.get('group') : groups[0];

    const [state, setState] = useState({
        loading: true,
        data: [],
        total: 0,
        pages: 0,
        page: 1,
        filter: {
            group: group
        },
        update: false,
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                group: group
            },
            update: new Date().getTime()
        }))
    }, [location.search])

    useEffect(() => {
        if (state.setFilter) {
            searchParams.set('page', state.page);

            Object.entries(state.filter).map(filter => {
                searchParams.set(filter[0], filter[1]);
            });

            history.push('?' + searchParams.toString());
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.update) {
            loadData()
        }
    }, [state.update])

    const loadData = () => {

        setState(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'partners/relationships/all?page=' + state.page;

        Object.entries(state.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages
                }));
            }).catch(err => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    // create
    const handleAdd = () => {
        modalAddOrEditRef.current.add();
    }

    const handleAddExist = () => {
        modalAddExistRef.current.add();
    }

    const handleCreate = () => {
        handleRefresh();
    }

    // update
    const handleEdit = id => {
        modalAddOrEditRef.current.edit(id);
    }

    const handleUpdate = partner => {

        let data = state.data.map(i => {
            if (i.id == partner.id) {
                i = partner;
            }
            return i;
        });

        setState(prev => ({
            ...prev,
            data: data
        }));
    }

    // delete
    const handleDelete = id => {
        Api.post('partners/relationships/remove', {
            related_partner_id: id
        }).then(res => {
            if (res.data.success) {
                handleRefresh();
            }
        });
    }

    // accept
    const handleAccept = id => {
        Api.post('partners/relationships/accept', {
            related_partner_id: id
        }).then(res => {
            if (res.data.success) {
                handleRefresh();
            }
        });
    }

    // decline
    const handleDecline = id => {
        Api.post('partners/relationships/decline', {
            related_partner_id: id
        }).then(res => {
            if (res.data.success) {
                handleRefresh();
            }
        });
    }

    const handleRefresh = () => {
        setState(prev => ({
            ...prev,
            page: 1,
            update: new Date().getTime()
        }));
    }

    // settings
    const handleShowSettings = id => {
        modalSettingsRef.current.open(id);
    }

    // Question modal
    const handleOpenQuestion = id => {
        modalQuestionRef.current.open(id);
    }

    let timeout;

    // Search
    const handleSearch = (key, val) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                page: 1,
                setFilter: new Date().getTime()
            }));
        }, 300);
    }

    const handlePage = page => {
        setState(prev => ({ ...prev, page: page.selected + 1, setFilter: new Date().getTime() }));
    }

    const handleTab = tab => {
        handleSearch('group', tab);

        if (tab === 'external') {
            setTimeout(() => {
                history.push('/relationships/selfemployed');
            }, 500);
        }
    }

    const moveCard = useCallback(
        (dragIndex, hoverIndex) => {
            const dragCard = state.data[dragIndex]
            setState(prev => ({
                ...prev,
                data: update(state.data, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard],
                    ],
                }),
            }))
        },
        [state.data]
    )

    const changePriority = ids => {
        Api.post('partners/relationships/settings/set-positions', { ids: ids });

        let data = [...state.data];

        ids.map((id, i) => {

            let index = data.findIndex(row => Number(row.related_partner_id) === Number(id));

            if (data[index]) {
                data[index].position = i + 1;
            }
        });

        setState(prev => ({
            ...prev,
            data: data
        }));
    }

    return (
        <>
            <AddOrEdit
                ref={modalAddOrEditRef}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
                handleRefresh={handleRefresh}
            />

            <AddExisting
                ref={modalAddExistRef}
                handleCreate={handleCreate}
            />

            <Question
                ref={modalQuestionRef}
                mainMessage={trans('headings.confirmDelete')}
                secondaryMessage={trans('subheadings.confirmDelete')}
                callback={handleDelete}
            />

            <Settings
                ref={modalSettingsRef}
                handleRefresh={handleRefresh}
            />

            <div className="wrapper">
                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {trans('headings.relationships')} <Help modalStyle={{ maxWidth: '600px' }}>{fromInfo('relationships')}</Help>
                        </h1>
                        <p className="page-subheading">
                            {trans('subheadings.relationships')}
                        </p>
                    </div>
                    <div className="right">
                        <div className="row">
                            <button className="button button-add" onClick={handleAddExist}>
                                {trans('buttons.addFromRegistered')}
                            </button>
                            <button className="button button-add" onClick={handleAdd}>
                                {trans('buttons.addNotRegistered')}
                            </button>
                        </div>
                    </div>
                </div>

                <Tabs
                    tab={state.filter.group}
                    handleTab={handleTab}
                />

                {/* <Filter
                    handleSearch={handleSearch}
                /> */}

                {state.loading
                    ?
                    <Skeleton height={45} count={10} />
                    :
                    <>
                        <Table
                            tab={state.filter.group}
                            data={state.data}
                            loading={state.loading}
                            handleEdit={handleEdit}
                            handleAccept={handleAccept}
                            handleDecline={handleDecline}
                            handleDelete={handleOpenQuestion}
                            handleShowSettings={handleShowSettings}
                            moveCard={moveCard}
                            changePriority={changePriority}
                        />

                        <Pagination
                            pages={state.pages}
                            page={state.page}
                            handlePage={handlePage}
                        />
                    </>
                }

            </div>
        </>
    )
}

export default Index;
import React, { createContext, useContext, useMemo, useState } from 'react'
import Echo from 'laravel-echo'
import socketio from 'socket.io-client'
import { useAuthDataContext } from './Auth'

export const SocketContext = createContext({});

let listener;

const SocketProvider = props => {

    const auth = useAuthDataContext();

    if (auth && auth.data) {
        if (!listener) {
            let token = auth.data.access_token;

            listener = new Echo({
                host: 'https://service.eusystem.eu:6001',
                // host: 'http://192.168.1.19:6001',
                broadcaster: 'socket.io',
                client: socketio,
                auth: {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                },
            });

        }
    }

    return <SocketContext.Provider value={listener} {...props} />

}

export const useSocketContext = () => useContext(SocketContext);

export default SocketProvider;

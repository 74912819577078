import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import Api from '../../../../_helpers/Api';
import Pagination from '../../../Pagination';
import Filter from '../../invoices/partials/Filter';
import loader from '../../../../assets/img/loader.svg';
import NoDataFound from '../../../partials/NoDataFound';
import Options from './ReservationOptions';
import FilterReservations from './FilterReservations';
import Success from '../../../modals/messages/Success'
import { trans } from '../../../../_providers/Translation';

import checkIcon from '../../../../assets/img/icons/check.png'
import closeIcon from '../../../../assets/img/icons/close.png'

let timeout;

function Reservations(props) {

    const params = useParams();
    const location = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(location.search);

    const status = searchParams.get('status') || '';
    const date = searchParams.get('date') || '';
    const deadline = searchParams.get('deadline') || '';
    const search = searchParams.get('search') || '';
    const paid_status = searchParams.get('paid_status') || '';

    const selectRef = useRef(null);
    const successSendModalRef = useRef(null);

    const [state, setState] = useState({
        loading: true,
        page: 1,
        data: [],
        total: 0,
        pages: 0,
        totals: {},
        filter: {

        },
        setFilter: false,
        update: false,
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                status: status,
                date: date,
                deadline: deadline,
                search: search,
                paid_status: paid_status,
            },
            update: new Date().getTime()
        }))
    }, [location.search])

    // useEffect(() => {
    //     console.log(state.filter);
    // }, [state.filter]);

    useEffect(() => {
        if (state.setFilter) {
            searchParams.set('page', state.page);

            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                searchParams.set(filter[0], filter[1]);
                // }
            });

            let url = '?' + searchParams.toString();

            history.push(url);
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.update) {
            loadData();
        }
    }, [state.update]);

    const loadData = () => {

        setState(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'partners/selfemployed/reservations?employee_id=' + params.id + '&page=' + state.page;

        Object.entries(state.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {

                setState(prev => ({
                    ...prev,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages,
                    totals: res.data.totals,
                }))
            })
            .finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            })
    }

    const refreshData = () => {
        setState(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    // Search
    const handleSearch = (key, val) => {
        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            },
            page: 1,
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                setFilter: new Date().getTime()
            }));
        }, 300);
    }

    const handlePage = page => {
        setState(prev => ({ ...prev, page: page.selected + 1, setFilter: Math.random().toString() }));
    }

    const handleShow = id => {
        history.push('/reservations/edit/' + id);
    }

    const handleAction = () => {
        let action = selectRef.current.value;
        let selected = [];

        document.querySelectorAll('.selected:checked').forEach(el => {
            selected.push(Number(el.value));
        })

        switch (action) {
            case 'paid':
                markAsPaid(selected);
                break;
            case 'notpaid':
                markAsUnpaid(selected);
                break;
        }
    }

    const markAsPaid = (selected) => {
        Api.post('partners/selfemployed/set-reservation-paid', {
            ids: selected,
        }).then(() => {
            onSuccess();
        })
    }

    const markAsUnpaid = (selected) => {
        Api.post('partners/selfemployed/set-reservation-unpaid', {
            ids: selected,
        }).then(() => {
            onSuccess();
        })
    }

    const onSuccess = () => {
        let modal = successSendModalRef.current;

        modal.open();
        modal.onClose(() => {
            refreshData();
        });
    }


    return (
        <>

            <Success
                ref={successSendModalRef}
                mainMessage={trans('messages.success')}
            />

            <FilterReservations
                filter={state.filter}
                handleSearch={handleSearch}
            />

            {state.loading
                ?
                <img src={loader} alt="loading" />
                :
                state.data.length === 0
                    ?
                    <NoDataFound />
                    :
                    <>
                        <div className="table-scroller collapsable-table">
                            <table className="collapsable active">
                                <thead>
                                    <tr>
                                        <th style={{
                                            width: '20px'
                                        }}></th>
                                        <th>
                                            ID
                                        </th>
                                        <th>
                                            {trans('tables.number')}
                                        </th>
                                        <th>
                                            {trans('tables.address')}
                                        </th>
                                        <th>
                                            {trans('tables.visitDate')}
                                        </th>
                                        <th>
                                            {trans('tables.visitHour')}
                                        </th>
                                        <th>
                                            {trans('tables.initialStatus')}
                                        </th>
                                        <th>
                                            {trans('tables.finalStatus')}
                                        </th>
                                        <th className="right" style={{ whiteSpace: 'nowrap' }}>
                                            {trans('tables.grossPrice')}
                                        </th>
                                        <th className="right" style={{ whiteSpace: 'nowrap' }}>
                                            {trans('selfEmployed.companyToEmployee')}
                                        </th>
                                        <th className="right" style={{ whiteSpace: 'nowrap' }}>
                                            {trans('selfEmployed.employeeToCompany')}
                                        </th>
                                        <th className="center">
                                            {trans('selfEmployed.balance')}
                                        </th>
                                        <th className="center">
                                            {trans('tables.isPaid')}
                                        </th>
                                        <th className="options">
                                            {trans('tables.options')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.data.map(r =>
                                        <tr
                                            key={'r-' + r.id}
                                            style={{ color: r.color }}
                                        >
                                            <td style={{
                                                width: '20px'
                                            }}>
                                                <input
                                                    type="checkbox"
                                                    className="selected"
                                                    name="selected[]"
                                                    value={r.id}
                                                />
                                            </td>
                                            <td onClick={() => handleShow(r.id)}>
                                                {r.id}
                                            </td>
                                            <td onClick={() => handleShow(r.id)}>
                                                <Link
                                                    to={`/reservations/edit/${r.id}`}
                                                    className="btn"
                                                    style={{ background: r.color, color: r.text_color }}
                                                >
                                                    {r.reference_code}
                                                </Link>
                                            </td>
                                            <td onClick={() => handleShow(r.id)}>
                                                {r.full_address}
                                            </td>
                                            <td onClick={() => handleShow(r.id)}>
                                                {r.visit_date
                                                    ? `${r.visit_date_dmy}`
                                                    : '-'
                                                }
                                            </td>
                                            <td onClick={() => handleShow(r.id)}>
                                                {r.visitperiod
                                                    ? `${r.visitperiod.period}`
                                                    : '-'
                                                }
                                            </td>
                                            <td onClick={() => handleShow(r.id)}>
                                                {r.initialstatus && r.initialstatus.translation.name}
                                            </td>
                                            <td onClick={() => handleShow(r.id)}>
                                                {r.finalstatus && r.finalstatus.translation.name}
                                            </td>
                                            <td className="right" style={{ whiteSpace: 'nowrap' }} onClick={() => handleShow(r.id)}>
                                                {r.total_price_formatted} {r.currency && r.currency.name}
                                            </td>
                                            <td className="right">
                                                <span className="tooltip" style={{ whiteSpace: 'nowrap' }} onClick={() => handleShow(r.id)}>
                                                    {Number(r.company_to_employee_price).toFixed(2)} {r.currency && r.currency.name}

                                                    <div className="tooltip-body with-arrow">
                                                        <table>
                                                            <tr>
                                                                <td>
                                                                    {trans('selfEmployed.collected')}:
                                                                </td>
                                                                <td style={{ width: '100px', textAlign: 'right' }}>
                                                                    <b style={{ whiteSpace: 'nowrap' }}>
                                                                        {Number(r.payments_price).toFixed(2)} {r.currency && r.currency.name}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {trans('selfEmployed.employeePricePercent')}:
                                                                </td>
                                                                <td style={{ width: '100px', textAlign: 'right' }}>
                                                                    <b style={{ whiteSpace: 'nowrap' }}>
                                                                        {Number(r.employee_price_percent).toFixed(0)}%
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {trans('selfEmployed.companyToEmployeePrice')}:
                                                                </td>
                                                                <td style={{ width: '100px', textAlign: 'right' }}>
                                                                    <b style={{ whiteSpace: 'nowrap' }}>
                                                                        {Number(r.company_to_employee_price).toFixed(2)} {r.currency && r.currency.name}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <div style={{
                                                            marginLeft: '-3px',
                                                            marginRight: '-3px',
                                                            marginTop: '5px',
                                                            padding: '3px',
                                                            border: '1px solid #ccc',
                                                            borderRadius: 'inherit'
                                                        }}>
                                                            <table>
                                                                <tr>
                                                                    <td>
                                                                        {trans('selfEmployed.labourPrice')}:
                                                                    </td>
                                                                    <td style={{ width: '100px', textAlign: 'right' }}>
                                                                        <b style={{ whiteSpace: 'nowrap' }}>
                                                                            {Number(r.employee_price).toFixed(2)} {r.currency && r.currency.name}
                                                                        </b>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        {trans('selfEmployed.partsPrice')}:
                                                                    </td>
                                                                    <td style={{ width: '100px', textAlign: 'right' }}>
                                                                        <b style={{ whiteSpace: 'nowrap' }}>
                                                                            {Number(r.company_to_employee_parts_price).toFixed(2)} {r.currency && r.currency.name}
                                                                        </b>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </span>
                                            </td>
                                            <td className="right" onClick={() => handleShow(r.id)}>
                                                <span className="tooltip" style={{ whiteSpace: 'nowrap' }}>
                                                    {Number(r.employee_to_company_price).toFixed(2)} {r.currency && r.currency.name}

                                                    <div className="tooltip-body with-arrow">
                                                        <table>
                                                            <tr>
                                                                <td>
                                                                    {trans('selfEmployed.collected')}:
                                                                </td>
                                                                <td style={{ width: '100px', textAlign: 'right' }}>
                                                                    <b style={{ whiteSpace: 'nowrap' }}>
                                                                        {Number(r.payments_price).toFixed(2)} {r.currency && r.currency.name}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {trans('selfEmployed.employeeToCompanyPrice')}:
                                                                </td>
                                                                <td style={{ width: '100px', textAlign: 'right' }}>
                                                                    <b style={{ whiteSpace: 'nowrap' }}>
                                                                        {Number(r.employee_to_company_price).toFixed(2)} {r.currency && r.currency.name}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        {(r.employee_to_company_other_price > 0 || r.employee_to_company_diagnostic_price > 0 || r.employee_to_company_labour_price > 0 || r.employee_to_company_transport_price > 0 || r.employee_to_company_parts_price > 0) &&
                                                            <div style={{
                                                                marginLeft: '-3px',
                                                                marginRight: '-3px',
                                                                marginTop: '5px',
                                                                padding: '3px',
                                                                border: '1px solid #ccc',
                                                                borderRadius: 'inherit'
                                                            }}>
                                                                <table>
                                                                    {/* ако е маркирано поотделно за диагностика, труд... */}
                                                                    {(r.employee_to_company_diagnostic_price > 0 || r.employee_to_company_labour_price > 0 || r.employee_to_company_transport_price > 0)
                                                                        ?
                                                                        <>
                                                                            {r.employee_to_company_diagnostic_price > 0 &&
                                                                                <tr>
                                                                                    <td>
                                                                                        {trans('selfEmployed.diagnosticPrice')}:
                                                                                    </td>
                                                                                    <td style={{ width: '100px', textAlign: 'right' }}>
                                                                                        <b style={{ whiteSpace: 'nowrap' }}>
                                                                                            {Number(r.employee_to_company_diagnostic_price).toFixed(2)} {r.currency && r.currency.name}
                                                                                        </b>
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                            {r.employee_to_company_labour_price > 0 &&
                                                                                <tr>
                                                                                    <td>
                                                                                        {trans('selfEmployed.labourPrice')}:
                                                                                    </td>
                                                                                    <td style={{ width: '100px', textAlign: 'right' }}>
                                                                                        <b style={{ whiteSpace: 'nowrap' }}>
                                                                                            {Number(r.employee_to_company_labour_price).toFixed(2)} {r.currency && r.currency.name}
                                                                                        </b>
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                            {r.employee_to_company_transport_price > 0 &&
                                                                                <tr>
                                                                                    <td>
                                                                                        {trans('selfEmployed.transportPrice')}:
                                                                                    </td>
                                                                                    <td style={{ width: '100px', textAlign: 'right' }}>
                                                                                        <b style={{ whiteSpace: 'nowrap' }}>
                                                                                            {Number(r.employee_to_company_transport_price).toFixed(2)} {r.currency && r.currency.name}
                                                                                        </b>
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {r.employee_to_company_other_price > 0 &&
                                                                                <tr>
                                                                                    <td>
                                                                                        {trans('selfEmployed.diagnosticAndLabourPrice')}:
                                                                                    </td>
                                                                                    <td style={{ width: '100px', textAlign: 'right' }}>
                                                                                        <b style={{ whiteSpace: 'nowrap' }}>
                                                                                            {Number(r.employee_to_company_other_price).toFixed(2)} {r.currency && r.currency.name}
                                                                                        </b>
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </>
                                                                    }
                                                                    {r.employee_to_company_parts_price > 0 &&
                                                                        <tr>
                                                                            <td>
                                                                                {trans('selfEmployed.partsPrice')}:
                                                                            </td>
                                                                            <td style={{ width: '100px', textAlign: 'right' }}>
                                                                                <b style={{ whiteSpace: 'nowrap' }}>
                                                                                    {Number(r.employee_to_company_parts_price).toFixed(2)} {r.currency && r.currency.name}
                                                                                </b>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                </table>
                                                            </div>
                                                        }
                                                    </div>
                                                </span>
                                            </td>
                                            <td className="center" onClick={() => handleShow(r.id)}>
                                                {r.company_to_employee_balance !== 0 &&
                                                    <label
                                                        className="btn"
                                                        style={{
                                                            whiteSpace: 'nowrap',
                                                            backgroundColor: r.company_to_employee_balance > 0 ? '#57c17f' : '#fc676b',
                                                            color: '#fff',
                                                            padding: '5px 30px'
                                                        }}>
                                                        {Number(r.company_to_employee_balance).toFixed(2)} {r.currency && r.currency.name}
                                                    </label>
                                                }
                                            </td>
                                            <td className="center" onClick={() => handleShow(r.id)}>
                                                {r.employee_paid
                                                    ?
                                                    <img src={checkIcon} height="12" />
                                                    :
                                                    <img src={closeIcon} height="12" />
                                                }
                                            </td>
                                            <td>
                                                <Options
                                                    id={r.id}
                                                    data={r}
                                                    masterId={r.id}
                                                    handleRefresh={refreshData}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td colSpan={9}>
                                            {trans('tables.total')}
                                        </td>
                                        <td className="right">
                                            <b>
                                                {Number(state.totals.company_to_employee_price).toFixed(2)} {state.totals.currency?.name}
                                            </b>
                                        </td>
                                        <td className="right">
                                            <b>
                                                {Number(state.totals.employee_to_company_price).toFixed(2)} {state.totals.currency?.name}
                                            </b>
                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={12}>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                color: '#595d7d',
                                                background: 'linear-gradient(to right, #fff, #fff, #d6dde5, #d6dde5)',
                                                borderRadius: '3px',
                                                paddingRight: '10px',
                                            }}>
                                                <div className="actions">
                                                    <select
                                                        ref={selectRef}
                                                    >
                                                        <option></option>
                                                        <option value="paid">{trans('buttons.markAsPaid')}</option>
                                                        <option value="notpaid">{trans('buttons.markAsUnpaid')}</option>
                                                    </select>
                                                    <button
                                                        onClick={handleAction}
                                                    >
                                                        {trans('buttons.applyForSelected')}
                                                    </button>
                                                </div>

                                                <div style={{
                                                    fontSize: '18px'
                                                }}>
                                                    {state.totals.balance > 0
                                                        ?
                                                        <span>
                                                            {trans('selfEmployed.companyToEmployee')}:
                                                        </span>
                                                        :
                                                        <span>
                                                            {trans('selfEmployed.employeeToCompany')}:
                                                        </span>
                                                    }
                                                    &nbsp;
                                                    <b style={{ marginLeft: '10px' }}>{Math.abs(Number(state.totals.balance)).toFixed(2)} {state.totals.currency?.name}</b>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <br />

                    </>
            }

            {!state.loading
                ?
                <Pagination
                    pages={state.pages}
                    page={state.page}
                    handlePage={handlePage}
                />
                :
                ''
            }
        </>
    )
}

export default Reservations;
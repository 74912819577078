import React, { createElement, useEffect, useState } from 'react';

import { useHistory, useParams, useLocation } from 'react-router-dom';
import { trans } from '../../../_providers/Translation';

//modals
import FilterTabs from './partials/FilterTabs';
import Incomes from './Incomes';
import Expenses from './Expenses';
import Vat from './Vat';
// import Unpaid from './Unpaid';
import ProfitVat from './ProfitVat';
// import UnpaidReservations from './UnpaidReservations';

let timeout;

function Accounts() {

    const params = useParams();
    const location = useLocation();
    const history = useHistory();

    const tabs = ['incomes', 'expenses', 'vat', 'profit-vat', 'unpaid', 'unpaid - reservations'];
    const tab = params.type;

    const [state, setState] = useState({
        tab: tabs.indexOf(tab) > -1 ? tab : tabs[0],
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            tab: tabs.indexOf(tab) > -1 ? tab : tabs[0],
        }));
    }, [location.pathname]);

    const handleTab = val => {

        history.push('/accounts/' + val);

        setState(prev => ({
            ...prev,
            tab: val,
        }))
    }

    const components = {
        'incomes': Incomes,
        'expenses': Expenses,
        'vat': Vat,
        'profit-vat': ProfitVat,
        // 'unpaid': <Unpaid />,
        // 'unpaid-reservations': <UnpaidReservations />
    }

    return (
        <>

            <div className="wrapper">

                <div className="top-part">
                    <div className="left">
                        <h1 className="page-heading">
                            {trans('headings.accounts')}
                        </h1>
                        <p className="page-subheading">
                            {trans('subheadings.accounts')}
                        </p>
                    </div>
                    <div className="right">

                    </div>
                </div>

                <FilterTabs
                    tab={state.tab}
                    handleTab={handleTab}
                />

                {createElement(components[state.tab])}

            </div>
        </>
    )
}

export default Accounts;
import React, { useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import Api from '../../../../../../_helpers/Api';

//images
import docIcon from '../../../../../../assets/img/icons/document.png';
import closeIcon from '../../../../../../assets/img/icons/close.png';
import loader from '../../../../../../assets/img/loader.svg';
import { trans } from '../../../../../../_providers/Translation';

function Notifications(props, ref) {

    const [popup, setPopup] = useState({
        overlay: false,
        modal: false,
        data: null,
        loading: false,
        onSave: null
    });

    const [employees, setEmployees] = useState([]);

    const [validations, setValidations] = useState(null);

    useImperativeHandle(ref, () => ({
        show: data => {
            setPopup(prev => ({
                ...prev,
                loading: false,
                data: data
            }));
            handleShow();
        },
        hide: () => {
            handleHide();
        },
        onSave: fn => {
            setPopup(prev => ({
                ...prev,
                onSave: fn
            }));
        }
    }));

    useEffect(() => {
        Api.get('partners/employees/all')
            .then(res => {
                setEmployees(res.data);
            });
    }, []);

    const handleShow = () => {
        setPopup(prev => ({
            ...prev,
            overlay: true
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: true,
                modal: true
            }));
        }, 50);
    }

    const handleHide = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            modal: false,
            loading: false,
        }));

        setTimeout(() => {
            setPopup(prev => ({
                ...prev,
                overlay: false,
                modal: false
            }));
        }, 50);
    }

    const handleSave = e => {
        e.preventDefault();

        setPopup(prev => ({
            ...prev,
            loading: true,
        }));

        setValidations(null);

        let url = 'partners/settings/edit';

        let data = new FormData(e.target);

        Api.post(url, data)
            .then(res => {

                handleHide(e);

                if (typeof popup.onSave === 'function') {
                    popup.onSave();
                }

                document.getElementById("form").reset();

            }).catch(error => {

                setPopup(prev => ({
                    ...prev,
                    loading: false,
                }));

                let _err = error.response;

                if (_err && _err.status && _err.status == 422) {
                    for (let [input, err] of Object.entries(_err.data.errors)) {

                        setValidations(prevState => {
                            return { ...prevState, [input]: true }
                        });
                    }
                }

            });
    }

    const handleInputChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        setPopup(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            }
        }));
    }

    return (
        <div className={`${popup.overlay ? 'visible' : ''} overlay`}>
            <form id="form" className={`${popup.modal ? 'show' : ''} popup-primary small`} onSubmit={handleSave}>
                <h2 className="head">
                    <img src={docIcon} alt="document" className="document-icon" />
                    {trans('headings.notificationsSettings')}
                    <img src={closeIcon} alt="close" className="close-icon" onClick={handleHide} />
                </h2>

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.customersOperator')}
                        </label>

                        <select
                            name="customers_operator_id"
                            value={popup.data && popup.data.customers_operator_id ? popup.data.customers_operator_id : ''}
                            onChange={handleInputChange}
                        >
                            {employees.map(e =>
                                <option key={'e-' + e.id} value={e.id}>{e.name} {e.lastname}</option>
                            )}
                        </select>

                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.allowInvoicePaidNotify')}
                        </label>

                        <select
                            name="allow_invoice_paid_notify"
                            value={popup.data && typeof popup.data.allow_invoice_paid_notify !== 'undefined' ? popup.data.allow_invoice_paid_notify : 1}
                            onChange={handleInputChange}
                        >
                            <option value="1">{trans('labels.yes')}</option>
                            <option value="0">{trans('labels.no')}</option>
                        </select>

                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.storageMinQty')}
                        </label>

                        <input
                            name="storage_min_quantity"
                            type="number"
                            min="0"
                            step="1"
                            className={validations && validations.storage_min_quantity ? 'validate' : ''}
                            value={popup.data && popup.data.storage_min_quantity ? popup.data.storage_min_quantity : ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.allowStorageQtyNotify')}
                        </label>

                        <select
                            name="allow_storage_quantity_notify"
                            value={popup.data && typeof popup.data.allow_storage_quantity_notify !== 'undefined' ? popup.data.allow_storage_quantity_notify : 1}
                            onChange={handleInputChange}
                        >
                            <option value="1">{trans('labels.yes')}</option>
                            <option value="0">{trans('labels.no')}</option>
                        </select>

                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.allowStorageSuggestionNotify')}
                        </label>

                        <select
                            name="allow_storage_suggestion_notify"
                            value={popup.data && typeof popup.data.allow_storage_suggestion_notify !== 'undefined' ? popup.data.allow_storage_suggestion_notify : 1}
                            onChange={handleInputChange}
                        >
                            <option value="1">{trans('labels.yes')}</option>
                            <option value="0">{trans('labels.no')}</option>
                        </select>

                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.allowClientAutoEmails')}
                        </label>

                        <select
                            name="allow_client_auto_emails"
                            value={popup.data && typeof popup.data.allow_client_auto_emails !== 'undefined' ? popup.data.allow_client_auto_emails : 1}
                            onChange={handleInputChange}
                        >
                            <option value="1">{trans('labels.yes')}</option>
                            <option value="0">{trans('labels.no')}</option>
                        </select>

                    </div>
                </div>


                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.allowUpcomingReservationNotify')}
                        </label>

                        <select
                            name="allow_upcoming_reservation_notify"
                            value={popup.data && typeof popup.data.allow_upcoming_reservation_notify !== 'undefined' ? popup.data.allow_upcoming_reservation_notify : 1}
                            onChange={handleInputChange}
                        >
                            <option value="1">{trans('labels.yes')}</option>
                            <option value="0">{trans('labels.no')}</option>
                        </select>

                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.allowDelayedReservationNotify')}
                        </label>

                        <select
                            name="allow_delayed_reservation_notify"
                            value={popup.data && typeof popup.data.allow_delayed_reservation_notify !== 'undefined' ? popup.data.allow_delayed_reservation_notify : 1}
                            onChange={handleInputChange}
                        >
                            <option value="1">{trans('labels.yes')}</option>
                            <option value="0">{trans('labels.no')}</option>
                        </select>

                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.allowInvoiceReminderNotify')}
                        </label>

                        <input
                            name="allow_invoice_payment_reminder_notify"
                            type="number"
                            min="0"
                            step="1"
                            value={popup.data && popup.data.allow_invoice_payment_reminder_notify ? popup.data.allow_invoice_payment_reminder_notify : ''}
                            onChange={handleInputChange}
                        />

                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.visitNotificationSms')}
                        </label>

                        <input
                            name="visit_notification_time"
                            type="number"
                            min="0"
                            step="1"
                            className={validations && validations.visit_notification_time ? 'validate' : ''}
                            value={popup.data && popup.data.visit_notification_time ? popup.data.visit_notification_time : ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.allowReassignNotify')}
                        </label>

                        <select
                            name="allow_reassign_sms_notify"
                            value={popup.data && typeof popup.data.allow_reassign_sms_notify !== 'undefined' ? popup.data.allow_reassign_sms_notify : 1}
                            onChange={handleInputChange}
                        >
                            <option value="1">{trans('labels.yes')}</option>
                            <option value="0">{trans('labels.no')}</option>
                        </select>
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.preferredSmsChannel')}
                        </label>

                        <select
                            name="preferred_sms_channel"
                            value={popup.data && typeof popup.data.preferred_sms_channel !== 'undefined' ? popup.data.preferred_sms_channel : 2}
                            onChange={handleInputChange}
                        >
                            <option value="2">Viber</option>
                            <option value="3">WhatsApp</option>
                        </select>
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.notificationsStoragePeriod')}
                        </label>

                        <input
                            name="notifications_storage_period"
                            type="number"
                            min="0"
                            step="1"
                            className={validations && validations.notifications_storage_period ? 'validate' : ''}
                            value={popup.data && popup.data.notifications_storage_period ? popup.data.notifications_storage_period : ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.smtpHost')}
                        </label>

                        <input
                            name="smtp_host"
                            type="text"
                            className={validations && validations.smtp_host ? 'validate' : ''}
                            value={popup.data && popup.data.smtp_host ? popup.data.smtp_host : ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.smtpPort')}
                        </label>

                        <input
                            name="smtp_port"
                            type="text"
                            className={validations && validations.smtp_port ? 'validate' : ''}
                            value={popup.data && popup.data.smtp_port ? popup.data.smtp_port : ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.smtpUsername')}
                        </label>

                        <input
                            name="smtp_username"
                            type="text"
                            className={validations && validations.smtp_username ? 'validate' : ''}
                            value={popup.data && popup.data.smtp_username ? popup.data.smtp_username : ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.smtpPassword')}
                        </label>

                        <input
                            name="smtp_password"
                            type="text"
                            className={validations && validations.smtp_password ? 'validate' : ''}
                            value={popup.data && popup.data.smtp_password ? popup.data.smtp_password : ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <br />

                <div className="popup-container">
                    <div className="col">
                        <label>
                            {trans('labels.smtpEncryption')}
                        </label>

                        <select
                            name="smtp_encryption"
                            value={popup.data?.smtp_encryption || ''}
                            onChange={handleInputChange}
                        >
                            <option value=""></option>
                            <option value={1}>{trans('labels.yes')}</option>
                            <option value={0}>{trans('labels.no')}</option>
                        </select>
                    </div>
                </div>

                <div className="footer align-center">
                    {popup.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button className="button" onClick={handleHide}>
                                {trans('buttons.cancel')}
                            </button>
                            <button className="button button-primary" type="submit">
                                {trans('buttons.save')}
                            </button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}

export default forwardRef(Notifications);
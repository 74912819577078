import React, { useEffect, useState, useRef } from 'react';
import Api from '../../../_helpers/Api';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import FilterTabs from './partials/FilterTabs';
import Skeleton from 'react-loading-skeleton';
import loader from '../../../assets/img/loader.svg';
import Reservations from './partials/Reservations';
import Stats from './partials/Stats';
import { trans } from '../../../_providers/Translation';

function Relationship(props) {

    const location = useLocation();
    const history = useHistory();
    const params = useParams();

    const searchParams = new URLSearchParams(location.search)

    const tabs = [
        {
            id: 'reservations',
            name: trans('tabs.reservations'),
            component: Reservations
        },
        {
            id: 'stats',
            name: trans('tabs.stats'),
            component: Stats
        },
    ];

    const tab = searchParams.get('tab') ? searchParams.get('tab') : tabs[0].id;

    const [state, setState] = useState({
        tab: tabs[0].id,
        index: 0,
        loading: true,
        data: null
    });

    useEffect(() => {

        setState(prev => ({
            ...prev,
            loading: true,
            tab: tab,
            index: findTabIndex(tab)
        }));

        Api.get('partners/selfemployed/find?id=' + params.id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data
                }));
            })
            .finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }, []);

    const handleTab = id => {

        let index = findTabIndex(id);

        setState(prev => ({
            ...prev,
            tab: id,
            index: index
        }));

        history.push('?tab=' + id);
    }

    const findTabIndex = id => {
        let index = tabs.findIndex(t => {
            return t.id === id;
        });

        return index;
    }

    return (
        <>

            <div className="wrapper">
                {state.loading
                    ?
                    <img src={loader} alt="loading" />
                    :
                    <>
                        <div className="top-part">
                            <div className="left">
                                <h1 className="page-heading">
                                    {state.data && state.data.name}
                                </h1>
                                <p className="page-subheading">
                                    {trans('subheadings.contractors')}
                                </p>
                            </div>
                        </div>

                        <FilterTabs
                            tabs={tabs}
                            tab={state.tab}
                            handleTab={handleTab}
                        />

                        {React.createElement(tabs[state.index].component)}
                    </>
                }
            </div>

        </>
    )
}

export default Relationship;